<template>
    <div class="recharge-record-wrap">
        <div>
            <img src="@/assets/images/user/recharge-icon.png" style="width: 35px;height: 35px;"/>
        </div>
        <div class="fx-fill fx-between" style="margin-left: 12px;">
            <div class="record-title">{{data.typeDesc}}</div>
            <div class="record-time">{{$getCalendar(data.addTime, 4, 3)}}</div>
        </div>
        <div class="fx-between">
            <div class="record-amount">{{data.amount | formatMoney}}</div>
            <div class="record-status">{{data.status | formatDesc}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => { return {}}
        }
    },
    filters: {
        formatMoney(val) {
            return Number(val).toFixed(2)
        },
        formatDesc(val) {
            return val === 'S' ? '' : '交易关闭'
        }
    }
}
</script>

<style lang="scss" scoped>
.recharge-record-wrap {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
	display: flex;
    flex-direction: row;
    border-bottom: 1px solid $border-color;
    padding: 12px 0px 16px 0;

    .record-title,.record-amount {
        font-size: 16px;
        padding-bottom: 10px;
    }

    .record-time,.record-status {
        font-size: 12px;
        color: $tips-color;
    }

    .record-amount,.record-status {
        text-align: right;
    }

    .record-amount {
        font-weight: 700;
    }
}
</style>
