<template>
<div class="root-wrap">
    <!-- <div class="header-wrap" ref="balance">
            <div class="balance-title">账户余额(元)</div>
            <div class="balance-amount">{{ memberInfo.memberAccountBalance || 0 | formatMoney }}</div>
        </div>
        <div class="header-slot">
            <div>交易明细</div>
            <div><van-tag plain text-color="#fff" style="background-color: transparent;border-color: #fff;" @click="goConsumePage">去查看</van-tag></div>
        </div> -->
    <div style="padding:5px;position: relative; min-height: 100vh;">
        <!-- <div class="fx-row">  -->
        <!--           <div class="fx-row"> -->
        <!-- <div class="sku-wrap"> -->
        <div style="padding:10px 0px 40px 0px" v-for="item, index of skuList" :key="index" @click="activeSku = index">
            <div class="header-wrap" @click="setCard(item.id)">
                <!-- <div>
                    <div>卡号：{{ item.card_num }}</div>
                    <div style="display: flex; justify-content: flex-end; align-items: center;">
                        <span>有效期</span>
                    </div>

                </div> -->
                <div style="display: flex; justify-content: space-between; align-items: center;font-size: large;">
                    <div>卡号：{{ item.card_num }}</div>
                    <div>
                        <span>有效期：{{ item.expire_time }}</span>
                    </div>
                </div>
                <div class="balance-title">余额(元)：</div>
                <div class="balance-amount">{{ item.amount || 0 | formatMoney }}</div>
            </div>
            <div class="header-slot">
                <div>交易明细</div>
                <div>
                    <van-tag plain text-color="#fff" style="background-color: transparent;border-color: #fff;" @click="goConsumePage(item.id)">去查看</van-tag>
                </div>
            </div>
        </div>

        <!-- </div> -->
        <!-- </div> -->

    </div>
    <div style="padding: 20px;">
        <van-button type="primary" style="position: fixed; bottom: 20px; left: 0; width: 100%; box-sizing: border-box; padding-top: 10px; padding-bottom: 10px;" block round @click="confirm">绑 卡</van-button>
    </div>

    <!-- <van-tabs v-model="activeIndex" class="fx-column" :scrollspy="false" :lazy-render="true" sticky :ellipsis="false" style="height: 100%;" :line-width="18">
                <van-tab title="我要充值">
                    <div class="fill-box fx-column">
                        <div class="fx-fill" style="padding: 16px;">
                            <div class="sku-wrap">
                                <div v-for="item, index of skuList" :key="index" class="sku-item" :class="activeSku == index ? 'active' : ''" @click="activeSku = index">
                                    <span>{{item.amount}}元</span>
                                    <span v-if="item.hasGift" class="sku-h">惠</span>
                                </div>
                            </div>
                            <div class="cfg-desc" v-html="activeCfg.cfgDesc"></div>
                            <div class="recharge-remark">
                                <div class="remark-title">充值说明:</div>
                                <div class="remark-content">
                                    <p>1.选择充值金额后生成支付宝付款二维码,保存到相册后使用支付宝"扫一扫"功能识别支付</p>
                                    <p>2.购买影票时可用账户余额支付</p>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div style="padding: 20px;">
                            <van-button type="primary" block round @click="confirm">确认充值</van-button>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="充值记录">
                    <div class="fill-box" style="background-color: #fff;">
                        <van-empty v-if="!rechargeRecordList || rechargeRecordList.length < 1" description="充值记录为空" :image="require('@/assets/images/user/recharge-empty.png')"></van-empty>
                        <div v-else class="recharge-record-container">
                            <recharge-record v-for="item,index in rechargeRecordList" :key="index" :data="item"></recharge-record>
                        </div>
                    </div>
                </van-tab>
            </van-tabs> -->
    <!-- </div> -->
    <!-- <div class="utils-wrap"> -->
    <!-- <van-overlay :show="show" @click="show = false">
            <div class="overlay-share">
                <vue-qr :text="rechargeUrl"></vue-qr>
                <div class="share-pic-tips">长按上方图片保存到相册</div>
                <div class="share-pic-tips">支付宝"扫一扫"相册识别</div>
                <div class="confirm-btn">
                    <van-button type="primary" block round>充值完成</van-button>
                </div>
            </div>
        </van-overlay> -->
    <div>
        <!-- <van-dialog
      v-model="show"
      title="绑定卡券"
      @confirm="handleConfirm"
      @cancel="show = false"
      show-cancel-button="false" 
      style="min-height: 300px; padding-top: 20px; padding-bottom: 20px;"> 
      <van-field
        v-model="cardSecret"
        label="卡密"
        placeholder="请输入卡密"
        input-align="right"
        :error-message="errorMessage"
        style="border: 1px solid #cccccc; margin-bottom: 20px; margin-top: 20px;"/> 
      <div style="text-align: center; margin-top: 20px;">
        <van-button type="primary" block @click="handleConfirm">确认</van-button>
      </div>
    </van-dialog> -->
        <van-dialog v-model="show" title="绑定卡券" show-cancel-button :lazy-render="false" @confirm="handleConfirm" :before-close="beforeClose">
            <van-field label="卡密：" v-model="cardNo" placeholder="请输入卡密" class="message" />
        </van-dialog>
    </div>
    <!-- </div> -->
</div>
</template>

<script>
import {
    getMemberCardList,
    rechargeConfirm,
    getRechargeList,
    bindCard,
    setCard
} from '@api/recharge-request'
import {
    sortBy
} from 'lodash'
import VueQr from 'vue-qr'
import RechargeRecord from '@/components/user/recharge-record'
export default {
    data() {
        return {
            activeIndex: 0,
            rechargeRecordList: [],
            skuList: [],
            activeSku: 0,
            rechargeUrl: '',
            show: false,
            cardNo: '',

            // orderId: this.$route.query.orderId,
        }
    },
    computed: {
        activeCfg() {
            return this.skuList[this.activeSku];
        },
        memberInfo() {
            return this.$store.state.user.memberInfo;
        },
    },
    created() {
        this.getMemberCardList();
        this.initPage();
        document.addEventListener('visibilitychange', this.initPage)
    },
    destroyed() {
        document.removeEventListener('visibilitychange', this.initPage);
    },
    methods: {
        confirm() {
            //TODO添加确认\
            this.show = true;
            this.cardNo = '';
            // let loading = this.$toast.loading('加载中')
            // rechargeConfirm(this.activeCfg.id).then((res) => {
            //     this.rechargeUrl = res;
            //     this.show = true;
            // }).finally(() => loading.clear())
        },
        getMemberCardList() {
            getMemberCardList().then((res) => {
                this.skuList = res;
            })
        },
        getRechargeList() {
            getRechargeList().then((res) => {
                this.rechargeRecordList = res.records;
            })
        },
        goConsumePage(cardId) {
            this.$goPage('user-transaction',{cardId})
        },
        initPage() {
            // this.getRechargeList();
            this.$store.dispatch('getMemberInfo');
        },
        handleConfirm() {
            if (this.cardNo.length < 1) {
                this.$toast({
                    message: '请输入卡密',
                    duration: 2000 // 持续时间，单位为毫秒，这里设置为2000毫秒，即2秒
                });

                return;
            }
            let loading = this.$toast.loading('加载中')
            let data = {
                code: this.cardNo
            }

            bindCard(data).then((res) => {
                this.$toast('绑定成功');
                this.show = false;
                this.getMemberCardList();
            }).finally(() => loading.clear())
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                // 如果是点击确认按钮，且需要阻止关闭，则调用 done(false)
                // 这里可以根据实际情况决定是否需要阻止关闭
                done(false);
            } else {
                // 如果是点击取消按钮，正常关闭对话框
                done();
            }
        },
        setCard(cardId) {
            let loading = this.$toast.loading('加载中')
            let data = {
                id: cardId
            }
            setCard(data).then((data) => {
                this.$toast('切换成功');
                this.$goPage(-1)
                // this.getMemberCardList();
            }).finally(() => loading.clear())
        }
    },
    filters: {
        formatMoney(val) {
            return Number(val).toFixed(2)
        },
    },
    // components: {
    //     VueQr,
    //     RechargeRecord
    // }
}
</script>

<style lang="scss" scoped>
.header-wrap {
    background-color: #fcc757;
    height: 140px;
    padding: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .balance-title {
        font-size: 14px;
    }

    .balance-amount {
        font-size: 32px;
    }
}

.header-slot {
    background-color: #f5b225;
    padding: 10px 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.body-wrap {
    position: relative;
    height: 100%;

    /deep/ .van-tabs__wrap {
        height: 40px;
    }

    /deep/ .van-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .van-tab__pane {
            flex: 1;
            position: relative;
        }
    }

    .recharge-record-container {
        background-color: #fff;
        padding: 15px;
        padding-top: 20px;
    }

    .sku-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        .sku-item {
            position: relative;
            z-index: 0;
            border: 1px solid $border-color;
            padding: 15px 0;
            border-radius: 4px;
            background-color: #fff;
            text-align: center;
            color: $content-color;
            font-size: 18px;

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                background-color: #fcc757;
                -webkit-box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
                box-shadow: 0 0.03rem 0.15rem rgba(255, 63, 62, 0.3);
                -webkit-transition: 0.2s;
                transition: 0.2s;
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            .sku-h {
                display: inline-block;
                padding: 1px 3px;
                position: absolute;
                right: 0;
                top: 0;
                background-color: #ff4500;
                border-bottom-left-radius: 2px;
                font-size: 10px;
                color: #fff;
                box-shadow: 1px 1px 1px #DEDEDF;
            }

            &.active::after {
                opacity: 0.3 !important;
                -webkit-transform: scale(1) !important;
                transform: scale(1) !important;
            }

            // &:not(:first-child) {
            // 	margin-left: 10px;
            // }

            .sku-item--current {
                color: #fff;
                background-color: #15161a;
                padding: 2px 3px;
                border-top-left-radius: 4px;
                border-bottom-right-radius: 4px;
                position: absolute;
                top: -10px;
                left: 0;
                z-index: 999;
            }

            .sku-second--name {
                font: 3.2vw/4vw simhei;
                font-size: 18px;
            }

            .sku-second--origin {
                text-decoration: line-through;
                font-size: 12px;
                color: $tips-color;
                white-space: nowrap;
            }

            .sku-second--price {
                font-size: 18px;
                color: #ff4500;
                padding: 6px 0;
                // border-bottom: 1px solid $border-color;
            }

            .sku-second--common {
                padding: 6px 0;
                font-size: 14px;
                color: $tips-color;
            }

            .sku-second--common-price {
                text-decoration: line-through;
                color: $tips-color;
            }

            &.active .sku-second--common {
                color: #15161a;
            }

            &.active .sku-second--common-price {
                color: #a52a2a;
            }
        }
    }

    .cfg-desc {
        height: 60px;
        margin-top: 20px;
        color: $tips-color;
    }

    .recharge-remark {
        .remark-title {
            position: relative;
            padding-left: 16px;
            font-size: 15px;
            font-weight: 700;
            line-height: 25px;
            height: 25px;
            margin-top: 30px;

            &::after {
                content: "";
                width: 6px;
                background-color: #f5b225;
                position: absolute;
                left: 0;
                height: 100%;
            }
        }

        .remark-content {
            color: $tips-color;
            margin-top: 10px;
        }
    }
}

.overlay-share {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.share-pic-tips {
    font-size: 20px;
    color: #efefef;
    margin-top: 8px;
}

// .message {
//   .van-field {
//     border: 1px solid #e2e2e2;
//     width: 90%;
//     margin: 12px auto 28px auto;
//     background: #f6f6f6;
//   }
// }
.message /deep/ .van-field__control {
    border: 1px solid #cccccc;
    border-radius: 4px;
}

/* 调整输入框的高度 */
.message /deep/ .van-field__control {
    height: 40px;
    /* 设置输入框的高度 */
}

/* 调整输入框内部的padding */
.message /deep/ .van-field__control input {
    padding: 20px 20px;
    /* 设置输入框内部的内边距 */
}

/* 调整标签的宽度和与输入区域的距离 */
.message /deep/ .van-field__label {
    width: 50px;
    /* 设置label的宽度 */
    margin-right: 2px;
    /* 设置label与输入区域之间的距离 */
    display: flex;
    /* 使用flex布局 */
    align-items: center;
    /* 竖直方向上居中 */
}
</style>
